import CustomImage from '@components/shared/customImage';
import React from 'react';
import classes from './try-it-free-mobile.module.scss';

const TryItFreemobile: React.FC = () => {
  return (
    <div className={classes.main}>
      <div className={classes.try_it_free_arch}></div>
      <div className={classes.try_it_free_heading}>
        <div className={classes.logo}>
          <CustomImage
            isStaticSrc={false}
            src={'images/home/try-it-free/logo.svg'}
            alt="logo"
            height="34.75"
            width="53.21"
          />
          <p className={classes.logo_text}>Well ProZ</p>
        </div>
        <div className={classes.sign}>
          {/* <div className={classes.sign_in}>
            <a
              href="https://wellproz.com/provider/signin"
              target="_blank"
              rel="noopener noreferrer"
              id={classes.sign_in_link}
            >
              Sign-in
            </a>
          </div> */}
          <div className={classes.sign_up}>
            <a
              href="https://wellproz.com/provider/signin"
              target="_blank"
              rel="noopener noreferrer"
              id={classes.sign_up_link}
            >
              Sign In
            </a>
          </div>
        </div>
      </div>
      <div className={classes.image}>
        <CustomImage
          isStaticSrc={false}
          className={classes.image_try}
          src={'images/home/try-it-free/try-it-free-mobile.png'}
          alt="logo"
          width={'1192px'}
          height={'1027px'}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.content_head}>
          <p className={classes.content_head_one}>
            Your patients are on the web.
          </p>
          <p className={classes.content_head_two}>You should be too.</p>
        </div>
        <div className={classes.content_sub}>
          <p className={classes.content_sub_one}>
            Meet your customers where they are and modernize your practice with
            cutting-edge e-commerce technology.
          </p>
          <p className={classes.content_sub_two}>
            Exclusively through Z Suite.
          </p>
        </div>
        <div className={classes.try}>
          <a href="#Demo" className={classes.try_link}>
            Try it Now
          </a>
        </div>
      </div>
      <div className={classes.e_commerce}>
        <div className={classes.e_commerce_head}>
          <p className={classes.e_commerce_head_one}>
            The future of <span>e-commerce</span> is here.
          </p>
          <p className={classes.e_commerce_head_two}>
            It&apos;s time to get on board.
          </p>
        </div>
        <p className={classes.e_commerce_sub}>
          Healthcare is changing. Are you ready to change with it? Compete with
          tech-savvy healthcare startups who are vying for your patients&apos;
          attention. With Well ProZ, you can improve patient outcomes and
          experiences with an all-in-one e-commerce platform built for
          healthcare professionals.
        </p>
      </div>
    </div>
  );
};

export default TryItFreemobile;
