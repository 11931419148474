import CustomImage from '@components/shared/customImage';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React from 'react';
import classes from './get-help-card-desktop.module.scss';

export interface GetHelpCardI {
  imageLink: string;
  text1: string;
  text2: string;
}

const GetHelpCardDesktop: React.FC<GetHelpCardI> = ({
  imageLink,
  text1,
  text2,
}) => {
  return (
    <Card className={classes.get_help_card}>
      <CardContent>
        <div className={classes.get_help_card_body}>
          <div className={classes.get_help_card_image}>
            <div className={classes.images}>
              <CustomImage
                isStaticSrc={false}
                src={imageLink}
                alt="card image"
                width="112px"
                height="112px"
              />
            </div>
          </div>
          <div className={classes.get_help_card_image_mobile}>
            <CustomImage
              isStaticSrc={false}
              src={imageLink}
              alt="card image"
              width="50px"
              height="50px"
            />
          </div>
          <div className={classes.get_help_card_text1}>{text1}</div>
          <div className={classes.get_help_card_text2}>{text2}</div>
        </div>
      </CardContent>
    </Card>
  );
};

export default GetHelpCardDesktop;
