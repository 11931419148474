import React from 'react';
import classes from './create-care-footer-mobile.module.scss';

const CreateCareFooterMobile: React.FC = () => {
  return (
    <div className={classes.create_care_footer}>
      <div className={classes.create_care_footer_description}>
        <div className={classes.create_care_footer_description_1}>60%</div>
        <div className={classes.create_care_footer_description_2}>
          improvement in patient retention by offering bundles{' '}
        </div>
      </div>
      <div className={classes.create_care_footer_description}>
        <div className={classes.create_care_footer_description_1}>8h+</div>
        <div className={classes.create_care_footer_description_2}>
          working hours saved each week
        </div>
      </div>
      <div className={classes.create_care_footer_description}>
        <div className={classes.create_care_footer_description_1}>50%</div>
        <div className={classes.create_care_footer_description_2}>
          less cost to acquire new patients{' '}
        </div>
      </div>
    </div>
  );
};

export default CreateCareFooterMobile;
