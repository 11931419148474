import { useViewport } from '@store/ViewPort';
import React from 'react';
import classes from './shop-builder.module.scss';
import ShopBuilderDesktop from './ShopBuilderDesktop';
import ShopBuilderMobile from './ShopBuilderMobile';

const ShopBuilder: React.FC = () => {
  const { width } = useViewport();
  const Width = width !== undefined ? width : 0;
  return (
    <div className={classes.shopbuilder}>
      {Width > 750 ? (
        <div className={classes.shopbuilder_desktop}>
          <ShopBuilderDesktop />{' '}
        </div>
      ) : (
        <ShopBuilderMobile />
      )}
    </div>
  );
};

export default ShopBuilder;
