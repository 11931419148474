import { useViewport } from '@store/ViewPort';
import React from 'react';
import classes from './get-help.module.scss';
import GetHelpDesktop from './GetHelpDesktop';
import GetHelpMobile from './GetHelpMobile';

const GetHelp: React.FC = () => {
  const { width } = useViewport();
  const Width = width !== undefined ? width : 0;

  return (
    <div className={classes.gethelp}>
      {Width > 750 ? <GetHelpDesktop /> : <GetHelpMobile />}
    </div>
  );
};
export default GetHelp;
