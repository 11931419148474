import { useViewport } from '@store/ViewPort';
import React from 'react';
import CreateCareDesktop from './CreateCareDesktop';
import CreateCareMobile from './CreateCareMobile';

const CreateCare: React.FC = () => {
  const { width } = useViewport();
  const Width = width !== undefined ? width : 0;
  return <>{Width > 750 ? <CreateCareDesktop /> : <CreateCareMobile />}</>;
};

export default CreateCare;
