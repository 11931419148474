import CustomImage from '@components/shared/customImage';
import React, { useState } from 'react';
import classes from './create-care-body.module.scss';

const CreateCareBody: React.FC = () => {
  const description_points = (text: string) => {
    return (
      <div className={classes.create_care_body_description_points}>
        <div className={classes.create_care_body_description_points_icon}>
          <CustomImage
            isStaticSrc={false}
            src={'images/home/icon-check.svg'}
            alt="create-care-image"
            width={16}
            height={16}
          />
        </div>
        <div className={classes.create_care_body_description_points_text}>
          {text}
        </div>
      </div>
    );
  };

  const [show, setshow] = useState({
    showOne: true,
    showTwo: false,
    showThree: false,
  });

  const scrollPoint = Math.floor(window.innerHeight / 2);

  window.onscroll = () => {
    const elementTwo = document.getElementById('two');
    if (elementTwo) {
      const twoPosition = Math.floor(elementTwo.getBoundingClientRect().top);
      if (twoPosition > scrollPoint) {
        setshow({
          showOne: true,
          showTwo: false,
          showThree: false,
        });
      } else if (scrollPoint - twoPosition <= 170) {
        setshow({
          showOne: false,
          showTwo: true,
          showThree: false,
        });
      } else {
        setshow({
          showOne: false,
          showTwo: false,
          showThree: true,
        });
      }
    }
  };

  return (
    <div className={classes.create_care_body}>
      <div className={classes.create_care_body_image}>
        <div
          className={
            show.showOne
              ? classes.create_care_body_image_one
              : classes.create_care_body_image_hide
          }
        >
          <CustomImage
            isStaticSrc={false}
            src={'images/home/create-care/one.svg'}
            alt="create-care"
            width={488}
            height={669}
          />
        </div>

        <div
          className={
            show.showTwo
              ? classes.create_care_body_image_two
              : classes.create_care_body_image_hide
          }
        >
          <CustomImage
            isStaticSrc={false}
            src={'images/home/create-care/two.svg'}
            alt="create-care"
            width={585}
            height={793}
          />
        </div>

        <div
          className={
            show.showThree
              ? classes.create_care_body_image_three
              : classes.create_care_body_image_hide
          }
        >
          <CustomImage
            isStaticSrc={false}
            src={'images/home/create-care/three.svg'}
            alt="create-care"
            width={639}
            height={793}
          />
        </div>
      </div>
      <div className={classes.create_care_body_description}>
        <div className={classes.create_care_body_description_header}>
          Create a care experience your patients love
        </div>
        <div className={classes.create_care_body_description_text}>
          For the first time ever, you can now offer clinical lab testing,
          practitioner grade supplements, your own services and more with
          shipping, fulfillment, and logistics all done-for-you in a single
          platform.
        </div>
        <div className={classes.create_care_body_description_main}>
          <div id="one" className={show.showOne ? classes.one : ''}>
            {description_points('Precision Wellness Testing')}
            <div className={classes.create_care_body_description_text}>
              Partnered exclusively through Vibrant Wellness labs to help your
              patients get to the root cause of their health issues.
            </div>
          </div>
          <div id="two" className={show.showTwo ? classes.one : ''}>
            {description_points('Smart Dispensary')}
            <div className={classes.create_care_body_description_text}>
              We have already done the heavy lifting to get you access to the
              best brands and dispensaries. Choose from thousands of
              Professional-grade supplements you love. Dispense virtual or
              In-Office!
            </div>
          </div>
          <div id="three" className={show.showThree ? classes.one : ''}>
            {description_points('Create Programs with Services')}
            <div className={classes.create_care_body_description_text}>
              Start creating programs that include lab tests, supplements, and
              your own professional services to deliver the best patient
              experience.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCareBody;
