import getConfig from 'next/config';
import Image, { ImageProps } from 'next/image';
import React from 'react';

interface CustomImageProps extends ImageProps {
  alt: string;

  isStaticSrc: boolean;
}

const { publicRuntimeConfig } = getConfig();
const { BASE_URL_PATH } = publicRuntimeConfig;
const getImagePath = (path: string) => `${BASE_URL_PATH}/${path}`;

const CustomImage: React.FC<CustomImageProps> = ({
  width,
  height,
  alt,
  src,
  layout,
  className,
  loading,
  isStaticSrc,
  ...otherProps
}): JSX.Element => {
  const render: JSX.Element =
    !isStaticSrc && typeof src == 'string' ? (
      <Image
        src={getImagePath(src)}
        className={className}
        loading={loading}
        layout={layout}
        alt={alt}
        width={width}
        height={height}
        {...otherProps}
      />
    ) : (
      <Image
        src={src}
        className={className}
        loading={loading}
        layout={layout}
        alt={alt}
        width={width}
        height={height}
        {...otherProps}
      />
    );

  return <> {render} </>;
};

export default CustomImage;
