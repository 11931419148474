import CustomImage from '@components/shared/customImage';
import { Divider } from '@mui/material';
import React, { useState } from 'react';
import classes from './shop-builder-desktop.module.scss';

// eslint-disable-next-line sonarjs/cognitive-complexity
const ShopBuilderDesktop: React.FC = () => {
  const [show, setShow] = useState({
    showCreate: true,
    showDesign: false,
    showProducts: false,
    showPublish: false,
  });

  const createChange = () => {
    setShow({
      showCreate: true,
      showDesign: false,
      showProducts: false,
      showPublish: false,
    });
  };

  const designChange = () => {
    setShow({
      showCreate: false,
      showDesign: true,
      showProducts: false,
      showPublish: false,
    });
  };

  const productChange = () => {
    setShow({
      showCreate: false,
      showDesign: false,
      showProducts: true,
      showPublish: false,
    });
  };

  const publishChange = () => {
    setShow({
      showCreate: false,
      showDesign: false,
      showProducts: false,
      showPublish: true,
    });
  };

  return (
    <div className={classes.shop_builder}>
      <div className={classes.shop_builder_head}>
        <p>Your personal Shop builder at your fingertips</p>
      </div>
      <div className={classes.shop_builder_filler}></div>
      <div className={classes.shop_builder_content}>
        {/* 1 */}
        <div className={classes.content}>
          <div className={show.showCreate ? classes.boxChange : classes.box}>
            <div
              className={classes.content_image}
              onMouseOver={() => createChange()}
            >
              {show.showCreate ? (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/create-blue.svg'}
                  alt="revenue"
                  height="49px"
                  width="49px"
                />
              ) : (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/create-black.svg'}
                  alt="revenue"
                  height="49px"
                  width="49px"
                />
              )}
            </div>
            <div className={classes.content_para}>
              <div
                className={show.showCreate ? classes.show : classes.head}
                onMouseOver={() => createChange()}
              >
                <p>Create a Shop</p>
              </div>
              {show.showCreate ? (
                <div className={classes.sub}>
                  <div className={classes.sub_heading}>
                    <p>Effortlessly build a new shop in minutes</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={24}
                      height={24}
                    />
                    <p>Connect to your existing Vibrant Portal </p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={24}
                      height={24}
                    />
                    <p>Add a payout method</p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {show.showCreate || show.showDesign ? (
          ''
        ) : (
          <div className={classes.divider}>
            <Divider />
          </div>
        )}

        {/* 2 */}
        <div className={classes.content}>
          <div className={show.showDesign ? classes.boxChange : classes.box}>
            <div
              className={classes.content_image}
              onMouseOver={() => designChange()}
            >
              {show.showDesign ? (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/design-blue.svg'}
                  alt="revenue"
                  height="49px"
                  width="49px"
                />
              ) : (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/design-black.svg'}
                  alt="revenue"
                  height="49px"
                  width="49px"
                />
              )}
            </div>
            <div className={classes.content_para}>
              <div
                className={show.showDesign ? classes.show : classes.head}
                onMouseOver={() => designChange()}
              >
                <p>Design & Customize</p>
              </div>
              {show.showDesign ? (
                <div className={classes.sub}>
                  <div className={classes.sub_heading}>
                    <p>Add your personality to your shop</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={24}
                      height={24}
                    />
                    <p>
                      Make it your own with custom images, videos, and colors{' '}
                    </p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={24}
                      height={24}
                    />
                    <p>No coding required </p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={24}
                      height={24}
                    />
                    <p>DIY or Done-for-You</p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {show.showDesign || show.showProducts ? (
          ''
        ) : (
          <div className={classes.divider}>
            <Divider />
          </div>
        )}
        {/* 3 */}
        <div className={classes.content}>
          <div className={show.showProducts ? classes.boxChange : classes.box}>
            <div className={classes.content_image} onMouseOver={productChange}>
              {show.showProducts ? (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/products-blue.svg'}
                  alt="revenue"
                  height="49px"
                  width="49px"
                />
              ) : (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/products-black.svg'}
                  alt="revenue"
                  height="49px"
                  width="49px"
                />
              )}
            </div>
            <div className={classes.content_para}>
              <div
                className={show.showProducts ? classes.show : classes.head}
                onMouseOver={productChange}
              >
                <p>Add Products & Services</p>
              </div>
              {show.showProducts ? (
                <div className={classes.sub}>
                  <div className={classes.sub_heading}>
                    <p>Choose from our vast product library</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={24}
                      height={24}
                    />
                    <p>Offer direct-to-patient lab testing </p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={24}
                      height={24}
                    />
                    <p>Fulfillment of practitioner-grade supplements</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={24}
                      height={24}
                    />
                    <p>Bundle your products and services</p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {show.showProducts || show.showPublish ? (
          ''
        ) : (
          <div className={classes.divider}>
            <Divider />
          </div>
        )}
        {/* 4 */}
        <div className={classes.content}>
          <div className={show.showPublish ? classes.boxChange : classes.box}>
            <div className={classes.content_image} onMouseOver={publishChange}>
              {show.showPublish ? (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/publish-blue.svg'}
                  alt="revenue"
                  height="49px"
                  width="49px"
                />
              ) : (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/publish-black.svg'}
                  alt="revenue"
                  height="49px"
                  width="49px"
                />
              )}
            </div>
            <div className={classes.content_para}>
              <div
                className={show.showPublish ? classes.show : classes.head}
                onMouseOver={publishChange}
              >
                <p>Publish & Start Selling</p>
              </div>
              {show.showPublish ? (
                <div className={classes.sub}>
                  <div className={classes.sub_heading}>
                    <p>Go live with your new online shop</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={24}
                      height={24}
                    />
                    <p>Marketing tools to grow and scale </p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={24}
                      height={24}
                    />
                    <p>Manage and strengthen patient relationships</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={24}
                      height={24}
                    />
                    <p>Reporting and analytics dashboards</p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {show.showPublish ? (
          ''
        ) : (
          <div className={classes.divider}>
            <Divider />
          </div>
        )}
      </div>
      <div className={classes.shop_builder_image}>
        <div className={show.showCreate ? classes.gifShow : classes.gifHide}>
          <CustomImage
            isStaticSrc={false}
            src={'images/home/shop-builder/create-gif.gif'}
            alt="shop-builder"
            width={'560px'}
            height={'430px'}
          />
        </div>
        <div className={show.showDesign ? classes.gifShow : classes.gifHide}>
          <CustomImage
            isStaticSrc={false}
            src={'images/home/shop-builder/design-gif.gif'}
            alt="shop-builder"
            width={'560px'}
            height={'430px'}
          />
        </div>
        <div className={show.showProducts ? classes.gifShow : classes.gifHide}>
          <CustomImage
            isStaticSrc={false}
            src={'images/home/shop-builder/product-gif.gif'}
            alt="shop-builder"
            width={'958px'}
            height={'728px'}
          />
        </div>
        <div className={show.showPublish ? classes.gifShow : classes.gifHide}>
          <CustomImage
            isStaticSrc={false}
            src={'images/home/shop-builder/publish-gif.gif'}
            alt="shop-builder"
            width={'509px'}
            height={'387px'}
          />
        </div>
      </div>
    </div>
  );
};

export default ShopBuilderDesktop;
