import { useViewport } from '@store/ViewPort';
import React from 'react';
import classes from './request-demo.module.scss';
import RequestDemoDesktop from './RequestDemoDesktop';
import RequestDemoMobile from './RequestDemoMobile';

const RequestDemo: React.FC = () => {
  const { width } = useViewport();
  const Width = width !== undefined ? width : 0;
  return (
    <div className={classes.requestdemo} id="Demo">
      {Width > 750 ? <RequestDemoDesktop /> : <RequestDemoMobile />}
    </div>
  );
};
export default RequestDemo;
