import CustomImage from '@components/shared/customImage';
import React from 'react';
import classes from './get-help-card-mobile.module.scss';

export interface GetHelpCardI {
  imageLink: string;
  text1: string;
  text2: string;
}

const GetHelpCardMobile: React.FC<GetHelpCardI> = ({
  imageLink,
  text1,
  text2,
}) => {
  return (
    <div className={classes.get_help_card}>
      <div className={classes.get_help_card_body}>
        <div className={classes.get_help_card_image_mobile}>
          <CustomImage
            isStaticSrc={false}
            src={imageLink}
            alt="card image"
            width={50}
            height={50}
          />
        </div>
        <div className={classes.get_help_card_text1}>{text1}</div>
        <div className={classes.get_help_card_text2}>{text2}</div>
      </div>
    </div>
  );
};

export default GetHelpCardMobile;
