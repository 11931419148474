import React from 'react';
import CreateCare from './CreateCare';
import Footer from './Footer';
import ForPatients from './ForPatients';
import GetHelp from './GetHelp';
import RequestDemo from './RequestDemo';
import ShopBuilder from './ShopBuilder';
import TryItFree from './TryItFree';

const Homecomponent: React.FC = () => {
  return (
    <div>
      <TryItFree />

      <ForPatients />

      <CreateCare />

      <ShopBuilder />

      <GetHelp />

      <RequestDemo />

      <Footer />
    </div>
  );
};

export default Homecomponent;
