import { AxiosResponse } from 'axios';
import { WELLPROZ_EMAIL_BASE_URL } from 'constants/urlConstants';
import https from './http-common';

/**
 * updated the url with the given api and added the interfaces/types accordingly
 * @param comments
 * @param contactNumber
 * @param email
 * @param firstName
 * @param lastName
 * @param state
 * @returns
 */
export const requestDemo = async (
  comments: string,
  contactNumber: string,
  email: string,
  firstName: string,
  lastName: string,
  state: string
): Promise<AxiosResponse> => {
  return https.post(`${WELLPROZ_EMAIL_BASE_URL}`, {
    comments: comments,
    contactNumber: contactNumber,
    email: email,
    firstName: firstName,
    lastName: lastName,
    state: state,
  });
};
