import CustomImage from '@components/shared/customImage';
import React from 'react';
import classes from './try-it-free-desktop-header.module.scss';

const TryItFreeDesktopHeader: React.FC = () => {
  return (
    <div className={classes.main}>
      <div className={classes.try_it_free}>
        <div className={classes.try_it_free_mainImage}>
          <CustomImage
            isStaticSrc={false}
            className={classes.image_try}
            src={'images/home/try-it-free/try-it-free-d.png'}
            alt="logo"
            width={1440}
            height={800}
          />
        </div>
        <div className={classes.try_it_free_heading}>
          <div className={classes.logo}>
            <CustomImage
              isStaticSrc={false}
              src={'images/home/try-it-free/wellproz.png'}
              alt="logo"
              height="53.21px"
              width="202.34px"
            />
          </div>
          <div className={classes.sign}>
            {/* <div className={classes.sign_in}>
              <a
                href="https://wellproz.com/provider/signin"
                target="_blank"
                rel="noopener noreferrer"
                id={classes.sign_in_link}
              >
                Sign-in
              </a>
            </div> */}
            <div className={classes.sign_up}>
              <a
                href="https://wellproz.com/provider/signin"
                target="_blank"
                rel="noopener noreferrer"
                id={classes.sign_up_link}
              >
                Sign In
              </a>
            </div>
          </div>
        </div>
        <div className={classes.try_it_free_main}>
          <div className={classes.content}>
            <div className={classes.content_head}>
              <p className={classes.content_head_one}>
                Your patients are on the web.
              </p>
              <p className={classes.content_head_two}>You should be too.</p>
            </div>
            <div className={classes.content_sub}>
              <p className={classes.content_sub_one}>
                Meet your customers where they are and modernize your practice
                with cutting-edge e-commerce technology.
              </p>
              <p className={classes.content_sub_two}>
                Exclusively through Z Suite.
              </p>
            </div>

            <div className={classes.try}>
              <a href="#Demo" className={classes.try_link}>
                Try it Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TryItFreeDesktopHeader;
