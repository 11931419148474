import CustomImage from '@components/shared/customImage';
import React from 'react';
import classes from './for-patients-mobile.module.scss';

const ForPatientsMobile: React.FC = () => {
  return (
    <div className={classes.for_patients}>
      <div className={classes.for_patients_head}>
        <p>
          Designed for <span className={classes.patients}>patients</span>. Built
          for <span className={classes.providers}>providers</span>.
        </p>
      </div>
      <div className={classes.for_patients_main}>
        <div className={classes.content}>
          <div className={classes.content_image}>
            <CustomImage
              isStaticSrc={false}
              src={'images/home/for-patients/revenue.svg'}
              alt="revenue"
              height="86px"
              width="80.46px"
            />
          </div>

          <p className={classes.content_head}>Unlock New Revenue Streams</p>
          <p className={classes.content_sub}>
            Converting leads into new patients is easy with a mobile-optimized
            store that simplifies the order process.
          </p>
        </div>
        <div className={classes.content}>
          <div className={classes.content_image}>
            <CustomImage
              isStaticSrc={false}
              src={'images/home/for-patients/experience.svg'}
              alt="experinece"
              height="74.74px"
              width="76px"
            />
          </div>

          <p className={classes.content_head}>Delightful Patient Experience</p>
          <p className={classes.content_sub}>
            Offer your patients a simple, seamless digital experience right at
            their fingertips. Boost your online reputation with a professional
            web presence and improve patient retention.
          </p>
        </div>
        <div className={classes.content}>
          <div className={classes.content_image}>
            <CustomImage
              isStaticSrc={false}
              src={'images/home/for-patients/store.svg'}
              alt="store"
              height="81.95px"
              width="89px"
            />
          </div>

          <p className={classes.content_head}>
            Your Branded Store <br />- No Coding Required
          </p>
          <p className={classes.content_sub}>
            Create and publish the beautiful online store of your dreams in just
            a few clicks.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForPatientsMobile;
