import React from 'react';
import CreateCareBody from './CreateCareBody';
import classes from './create-care-desktop.module.scss';
import CreateCareFooter from './CreateCareFooter';

const CreateCareDesktop: React.FC = () => {
  return (
    <>
      <div className={classes.create_care_body}>
        <CreateCareBody />
      </div>
      <div className={classes.create_care_footer}>
        <CreateCareFooter />
      </div>
    </>
  );
};

export default CreateCareDesktop;
