import React from 'react';
import classes from './try-it-free-desktop-footer.module.scss';

const TryItFreeDesktopFooter: React.FC = () => {
  return (
    <div className={classes.main}>
      <div className={classes.e_commerce}>
        
        <div className={classes.e_commerce_head}>
          <p className={classes.e_commerce_head_one}>
            The future of <span>e-commerce</span> is here.
          </p>
          <p className={classes.e_commerce_head_two}>
            It&apos;s time to get on board.
          </p>
        </div>
        <p className={classes.e_commerce_sub}>
          Healthcare is changing. Are you ready to change with it? Compete with
          tech-savvy healthcare startups who are vying for your patients&apos;
          attention. With Well ProZ, you can provide better patient outcomes and
          experiences with an all-in-one e-commerce platform built for
          healthcare professionals.
        </p>
      </div>
    </div>
  );
};

export default TryItFreeDesktopFooter;
