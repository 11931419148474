import React from 'react';
import CreateCareBodyMobile from './CreateCareBodyMobile';
import CreateCareFooterMobile from './CreateCareFooterMobile';
import classes from './create-care-mobile.module.scss';

const CreateCareMobile: React.FC = () => {
  return (
    <div className={classes.create_care}>
      <div className={classes.create_care_body}>
        <CreateCareBodyMobile />
      </div>
      <div className={classes.create_care_footer}>
        <CreateCareFooterMobile />
      </div>
    </div>
  );
};

export default CreateCareMobile;
