import CustomImage from '@components/shared/customImage';
import React from 'react';
import classes from './create-care-body-mobile.module.scss';

const CreateCareBodyMobile: React.FC = () => {
  const description_points = (text: string) => {
    return (
      <div className={classes.create_care_body_description_points}>
        <div className={classes.create_care_body_description_points_icon}>
          <CustomImage
            isStaticSrc={false}
            src={'images/home/icon-check.svg'}
            alt="create-care-image"
            width={16}
            height={16}
          />
        </div>
        <div className={classes.create_care_body_description_points_text}>
          {text}
        </div>
      </div>
    );
  };
  return (
    <div className={classes.create_care_body}>
      <div className={classes.create_care_body_image}>
        <CustomImage
          isStaticSrc={false}
          src={'images/home/create-care-image.svg'}
          alt="create-care-image"
          width={650}
          height={797}
        />
      </div>
      <div className={classes.create_care_body_description}>
        <div className={classes.create_care_body_description_header}>
          Create a care experience your patients love
        </div>
        <div className={classes.create_care_body_description_text}>
          For the first time ever, you can offer clinical lab testing,
          practitioner-grade supplements, custom services, and more, with
          shipping, fulfillment, and logistics done for you&mdash;on a single
          platform.
        </div>
        {description_points('Precision Wellness Testing')}
        <div className={classes.create_care_body_description_text}>
          Help your patients get to the root cause of their health issues by
          offering a vast menu of specialty wellness lab testing.
        </div>
        {description_points('Smart Dispensary')}
        <div className={classes.create_care_body_description_text}>
          We handle the heavy lifting to give you access to the best brands and
          dispensaries. Choose from thousands of professional-grade supplements
          you love. Dispense virtually or in-office!
        </div>
        {description_points('Create Programs with Services')}
        <div className={classes.create_care_body_description_text}>
          Create custom bundles that feature lab tests, supplements, and your
          own professional services to deliver the best patient experience.
        </div>
      </div>
    </div>
  );
};

export default CreateCareBodyMobile;
