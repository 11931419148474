import CustomImage from '@components/shared/customImage';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { useState } from 'react';
import classes from './shop-builder-mobile.module.scss';
// eslint-disable-next-line sonarjs/cognitive-complexity
const ShopBuilderMobile: React.FC = () => {
  const [show, setShow] = useState({
    showCreate: true,
    showDesign: false,
    showProducts: false,
    showPublish: false,
  });

  const createChange = () => {
    setShow({
      showCreate: true,
      showDesign: false,
      showProducts: false,
      showPublish: false,
    });
  };

  const designChange = () => {
    setShow({
      showCreate: false,
      showDesign: true,
      showProducts: false,
      showPublish: false,
    });
  };

  const productChange = () => {
    setShow({
      showCreate: false,
      showDesign: false,
      showProducts: true,
      showPublish: false,
    });
  };

  const publishChange = () => {
    setShow({
      showCreate: false,
      showDesign: false,
      showProducts: false,
      showPublish: true,
    });
  };

  return (
    <div className={classes.shop_builder}>
      <div className={classes.shop_builder_head}>
        <p>Your personal Shop builder at your fingertips</p>
      </div>

      <div className={classes.shop_builder_content}>
        {/* 1 */}
        <div className={classes.content}>
          <div className={show.showCreate ? classes.boxChange : classes.box}>
            <div className={classes.content_image} onClick={createChange}>
              {show.showCreate ? (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/create-blue.svg'}
                  alt="revenue"
                  height="24px"
                  width="24px"
                />
              ) : (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/create-black.svg'}
                  alt="revenue"
                  height="24px"
                  width="24px"
                />
              )}
            </div>
            <div className={classes.content_para}>
              <div
                className={show.showCreate ? classes.show : classes.head}
                onClick={createChange}
              >
                <div className={classes.head_mobile}>
                  <p>create a shop</p>
                  <div>{show.showCreate ? <RemoveIcon /> : <AddIcon />}</div>
                </div>
              </div>
              {show.showCreate ? (
                <div className={classes.sub}>
                  <div className={classes.sub_heading}>
                    <p>Effortlessly build a new shop in minutes</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={18}
                      height={18}
                    />
                    <p>Connect to your existing Vibrant Portal </p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={18}
                      height={18}
                    />
                    <p>Add a payout method</p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className={classes.shop_builder_image}>
          {show.showCreate ? (
            <CustomImage
              isStaticSrc={false}
              src={'images/home/shop-builder/create-gif.gif'}
              alt="shop-builder"
              width={'280px'}
              height={'215px'}
            />
          ) : (
            ''
          )}
        </div>
        {/* 2 */}
        <div className={classes.content}>
          <div className={show.showDesign ? classes.boxChange : classes.box}>
            <div className={classes.content_image} onClick={designChange}>
              {show.showDesign ? (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/design-blue.svg'}
                  alt="revenue"
                  height="24px"
                  width="24px"
                />
              ) : (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/design-black.svg'}
                  alt="revenue"
                  height="24px"
                  width="24px"
                />
              )}
            </div>
            <div className={classes.content_para}>
              <div
                className={show.showDesign ? classes.show : classes.head}
                onClick={designChange}
              >
                <div className={classes.head_mobile}>
                  <p>Design & Customize</p>
                  <div>{show.showDesign ? <RemoveIcon /> : <AddIcon />}</div>
                </div>
              </div>
              {show.showDesign ? (
                <div className={classes.sub}>
                  <div className={classes.sub_heading}>
                    <p>Add your personality to your shop</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={18}
                      height={18}
                    />
                    <p>
                      Make it your own with custom images, videos, and colors{' '}
                    </p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={18}
                      height={18}
                    />
                    <p>No coding required </p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={18}
                      height={18}
                    />
                    <p>DIY or Done-for-You</p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className={classes.shop_builder_image}>
          {show.showDesign ? (
            <CustomImage
              isStaticSrc={false}
              src={'images/home/shop-builder/design-gif.gif'}
              alt="shop-builder"
              width={'280px'}
              height={'215px'}
            />
          ) : (
            ''
          )}
        </div>
        {/* 3 */}
        <div className={classes.content}>
          <div className={show.showProducts ? classes.boxChange : classes.box}>
            <div className={classes.content_image} onClick={productChange}>
              {show.showProducts ? (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/products-blue.svg'}
                  alt="revenue"
                  height="24px"
                  width="24px"
                />
              ) : (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/products-black.svg'}
                  alt="revenue"
                  height="24px"
                  width="24px"
                />
              )}
            </div>
            <div className={classes.content_para}>
              <div
                className={show.showProducts ? classes.show : classes.head}
                onClick={productChange}
              >
                <div className={classes.head_mobile}>
                  <p>Add Products & Services</p>
                  <div>{show.showProducts ? <RemoveIcon /> : <AddIcon />}</div>
                </div>
              </div>
              {show.showProducts ? (
                <div className={classes.sub}>
                  <div className={classes.sub_heading}>
                    <p>Choose from our vast product library</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={18}
                      height={18}
                    />
                    <p>Offer direct-to-patient lab testing </p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={18}
                      height={18}
                    />
                    <p>Fulfillment of practitioner-grade supplements</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={18}
                      height={18}
                    />
                    <p>Bundle your products and services</p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className={classes.shop_builder_image}>
          {show.showProducts ? (
            <CustomImage
              isStaticSrc={false}
              src={'images/home/shop-builder/product-gif.gif'}
              alt="shop-builder"
              width={'252px'}
              height={'193px'}
            />
          ) : (
            ''
          )}
        </div>
        {/* 4 */}
        <div className={classes.content}>
          <div className={show.showPublish ? classes.boxChange : classes.box}>
            <div className={classes.content_image} onClick={publishChange}>
              {show.showPublish ? (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/publish-blue.svg'}
                  alt="revenue"
                  height="24px"
                  width="24px"
                />
              ) : (
                <CustomImage
                  isStaticSrc={false}
                  src={'images/home/shop-builder/publish-black.svg'}
                  alt="revenue"
                  height="24px"
                  width="24px"
                />
              )}
            </div>
            <div className={classes.content_para}>
              <div
                className={show.showPublish ? classes.show : classes.head}
                onClick={publishChange}
              >
                <div className={classes.head_mobile}>
                  <p>Publish & Start Selling</p>
                  <div>{show.showPublish ? <RemoveIcon /> : <AddIcon />}</div>
                </div>
              </div>
              {show.showPublish ? (
                <div className={classes.sub}>
                  <div className={classes.sub_heading}>
                    <p>Go live with your new online shop</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={18}
                      height={18}
                    />
                    <p>Marketing tools to grow and scale </p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={18}
                      height={18}
                    />
                    <p>Manage and strengthen patient relationships</p>
                  </div>
                  <div className={classes.sub_points}>
                    <CustomImage
                      isStaticSrc={false}
                      src="images/home/shop-builder/check.svg"
                      alt="Picture of the author"
                      width={18}
                      height={18}
                    />
                    <p>Reporting and analytics dashboards</p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className={classes.shop_builder_image}>
          {show.showPublish ? (
            // <div>
            <CustomImage
              isStaticSrc={false}
              src={'images/home/shop-builder/publish-gif.gif'}
              alt="shop-builder"
              width={'283px'}
              height={'215px'}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopBuilderMobile;
