import React from 'react';
import classes from './try-it-free-desktop.module.scss';
import TryItFreeDesktopFooter from './TryItFreeDesktopFooter';
import TryItFreeDesktopHeader from './TryItFreeDesktopHeader';

const TryItFreeDesktop: React.FC = () => {
  return (
    <>
      <div className={classes.tryitfreeheader}>
        <TryItFreeDesktopHeader />
      </div>
      <div className={classes.tryitfreefooter}>
        <TryItFreeDesktopFooter />
      </div>
    </>
  );
};

export default TryItFreeDesktop;
