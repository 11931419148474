import { requestDemo } from '@services/index';
import { stateList } from 'constants/urlConstants';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import React from 'react';
import * as yup from 'yup';
import classes from './request-demo-desktop.module.scss';

const RequestDemoDesktop: React.FC = () => {
  const router = useRouter();
  const validationSchema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    state: yup.string().required(),
    contact: yup.number().required(),
    hearAboutUs: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      contact: '',
      state: '',
      hearAboutUs: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      try {
        requestDemo(
          values.hearAboutUs,
          values.contact,
          values.email,
          values.firstName,
          values.lastName,
          values.state
        ).then((res) => {
          console.log('response check', res);
          router.push('/confirmation');
        });
      } catch (error) {
        alert('Failed to submit,Try again after sometime');
      }
    },
  });

  return (
    <>
      <div className={classes.request_demo}>
        <div className={classes.request_demo_check}>
          <form
            onSubmit={formik.handleSubmit}
            className={classes.request_demo_form}
          >
            <p className={classes.request_demo_form_headline}>Try a demo</p>
            <div className={classes.request_demo_form_field}>
              <input
                id="firstName"
                className={
                  formik.touched.firstName && formik.errors.firstName
                    ? classes.error
                    : classes.each_field
                }
                name="firstName"
                type="text"
                placeholder="First Name"
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
            </div>
            <div className={classes.request_demo_form_field}>
              <input
                id="lastName"
                className={
                  formik.touched.lastName && formik.errors.lastName
                    ? classes.error
                    : classes.each_field
                }
                name="lastName"
                type="text"
                placeholder="Last Name"
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
            </div>

            <div className={classes.request_demo_form_field}>
              <input
                id="email"
                className={
                  formik.touched.email && formik.errors.email
                    ? classes.error
                    : classes.each_field
                }
                name="email"
                type="email"
                placeholder="Email Address"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            <div className={classes.request_demo_form_field}>
              <input
                id="contact"
                className={
                  formik.touched.contact && formik.errors.contact
                    ? classes.error
                    : classes.each_field
                }
                name="contact"
                type="tel"
                // pattern="[0-9]{10}"
                placeholder="Contact Number"
                onChange={formik.handleChange}
                value={formik.values.contact}
              />
            </div>
            <div className={classes.request_demo_form_field}>
              <select
                name="state"
                id="state"
                className={
                  formik.touched.state && formik.errors.state
                    ? classes.error
                    : classes.each_field
                }
                onChange={formik.handleChange}
                value={formik.values.state}
              >
                <option value="" disabled selected hidden>
                  State{' '}
                </option>
                {stateList.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className={classes.request_demo_form_field}>
              <input
                id="hearAboutUs"
                className={
                  formik.touched.hearAboutUs && formik.errors.hearAboutUs
                    ? classes.error
                    : classes.each_field
                }
                name="hearAboutUs"
                type="text"
                placeholder="How did you hear about us?"
                onChange={formik.handleChange}
                value={formik.values.hearAboutUs}
              />
            </div>

            <button type="submit" className={classes.button}>
              See how it works
            </button>
          </form>
          <div className={classes.request_demo_try_box}>
            <p className={classes.request_demo_try_box_headline}>
              Ready for a stress-free way to help your practice thrive?
            </p>
            <p className={classes.request_demo_try_box_links}>
              <a>Increase visits</a>, <a> wow patients</a>, and{' '}
              <a>save staff</a> time with <span> Well ProZ. </span>{' '}
            </p>
          </div>
        </div>

        <div className={classes.request_demo_try}></div>
      </div>
    </>
  );
};

export default RequestDemoDesktop;
