import { useViewport } from '@store/ViewPort';
import React from 'react';
import classes from './for-patients.module.scss';
import ForPatientsDesktop from './ForPatientsDesktop';
import ForPatientsMobile from './ForPatientsMobile';

const ForPatients: React.FC = () => {
  const { width } = useViewport();
  const Width = width !== undefined ? width : 0;
  return (
    <div className={classes.forpatients}>
      {Width > 750 ? (
        <div className={classes.forpatients_desktop}>
          <ForPatientsDesktop />
        </div>
      ) : (
        <ForPatientsMobile />
      )}
    </div>
  );
};

export default ForPatients;
