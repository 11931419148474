import React from 'react';
import classes from './create-care-footer.module.scss';

const CreateCareFooter: React.FC = () => {
  return (
    <div className={classes.create_care_footer}>
      <div className={classes.create_care_footer_description}>
        <div className={classes.create_care_footer_description_1}>60%</div>
        <div className={classes.create_care_footer_description_2}>
          improvement in patient retention by offering bundles
        </div>
      </div>
      <div className={classes.create_care_footer_line}></div>
      <div className={classes.create_care_footer_description}>
        <div className={classes.create_care_footer_description_1}>8h+</div>
        <div className={classes.create_care_footer_description_2}>
          working hours saved each week
        </div>
      </div>
      <div className={classes.create_care_footer_line}></div>
      <div className={classes.create_care_footer_description}>
        <div className={classes.create_care_footer_description_3}>50%</div>
        <div className={classes.create_care_footer_description_2}>
          less cost to acquire new patients{' '}
        </div>
      </div>
    </div>
  );
};

export default CreateCareFooter;
