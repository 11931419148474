import axios from 'axios';
const headers = {
  'Content-type': 'application/json',
};

const axiosApiInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_SERVICE_ROUTE}`,
  headers: {
    'Content-type': 'application/json',
  },
});

export default axiosApiInstance;
