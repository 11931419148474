import type { NextPage } from "next";
import { Fragment } from "react";
import Homecomponent from "../components/homeComponent";

const Home: NextPage = () => {
  return (
    <Fragment>
      <Homecomponent />
    </Fragment>
  );
};

export default Home;
