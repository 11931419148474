import { useViewport } from '@store/ViewPort';
import React from 'react';
import TryItFreeDesktop from './TryItFreeDesktop';
import TryItFreeMobile from './TryItFreeMobile';

const TryItFree: React.FC = () => {
  const { width } = useViewport();
  const Width = width !== undefined ? width : 0;

  return (
    <div>
      {Width > 750 ? (
        <div>
          <TryItFreeDesktop />
        </div>
      ) : (
        <TryItFreeMobile />
      )}
    </div>
  );
};

export default TryItFree;
