import { useViewport } from '@store/ViewPort';
import React from 'react';
import FooterDesktop from './FooterDesktop';
import FooterMobile from './FooterMobile';
import classes from './footer.module.scss';

const Footer: React.FC = () => {
  const { width } = useViewport();
  const Width = width !== undefined ? width : 0;
  return (
    <div className={classes.footer}>
      {Width > 750 ? (
        <div className={classes.footer_desktop}>
          <FooterDesktop />
        </div>
      ) : (
        <FooterMobile />
      )}
    </div>
  );
};

export default Footer;
