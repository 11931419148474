import React from 'react';
import classes from './get-help-desktop.module.scss';
import GetHelpCardDesktop from './GetHelpCardDesktop';

const GetHelpDesktop: React.FC = () => {
  return (
    <div className={classes.get_help_body}>
      <div className={classes.get_help_text}>
        Get the help you need every step of the way.
      </div>
      <div className={classes.get_help_card}>
        <GetHelpCardDesktop
          imageLink={'images/home/get-help/seo.svg'}
          text1={'Enhance your online presence'}
          text2={
            'Let our business experts help you with everything from store setup to SEO. '
          }
        />
        <GetHelpCardDesktop
          imageLink={'images/home/get-help/a-1.svg'}
          text1={'Receive premium support'}
          text2={
            "Whether you're troubleshooting issues or seeking business advice, contact our experts 24/7."
          }
        />
        <GetHelpCardDesktop
          imageLink={'images/home/get-help/a-2.svg'}
          text1={'Elevate your business '}
          text2="Focus on providing the care you love&#8212;let us handle the rest."
        />
      </div>
    </div>
  );
};
export default GetHelpDesktop;
